import { extendTheme } from "@chakra-ui/react"

import { colors } from "./colors"
import { Button } from "./components/Button"
import { Input } from "./components/Input"
import { Select } from "./components/Select"
import { Textarea } from "./components/Textarea"

export const theme = extendTheme({
  config: {
    useSystemColorMode: false,
    initialColorMode: "dark",
  },
  fonts: {
    body: "Intro, sans-serif",
    heading: "Intro, serif",
  },
  colors,
  components: {
    Tabs: {
      baseStyle: { tabpanel: { px: 0 }, tab: { fontWeight: "black" } },
    },
    Button,
    Input,
    Select,
    Textarea,
  },
})
