// don't import files or modules into this file
const { APP_ENV } = process.env
let env: "production" | "development" | "staging"

if (APP_ENV) {
  env = APP_ENV as "production" | "development" | "staging"
} else {
  const hostname = typeof window !== "undefined" && window?.location?.hostname
  if (hostname) {
    if (hostname.includes("noquarter")) {
      env = "staging"
    } else if (hostname.includes("kunstbende")) {
      env = "production"
    } else {
      env = "development"
    }
  } else {
    env = "development"
  }
}

export const IS_PRODUCTION = env === "production"
export const IS_STAGING = env === "staging"
export const IS_DEV = env === "development"

export const REDIRECT_PATH = "redirect"

export const SENTRY_DSN = "https://c33dca4b2027454da1c39d5bda0c7a4c@o204549.ingest.sentry.io/5527138"
export const API_URL = IS_PRODUCTION
  ? "https://nq-kunstbende-production.herokuapp.com"
  : IS_STAGING
  ? "https://nq-kunstbende-staging.herokuapp.com"
  : "http://localhost:5555"

export const WEB_URL = IS_PRODUCTION
  ? "kunstbende.nl"
  : IS_STAGING
  ? "kunstbende.noquarter.co"
  : "localhost:3000"

export const GRAPHQL_API_URL = `${API_URL}/graphql`

export const ACCESS_TOKEN = `kunstbende.access.token${IS_PRODUCTION ? "" : `.${env}`}`
export const REFRESH_TOKEN = `kunstbende.refresh.token${IS_PRODUCTION ? "" : `.${env}`}`
export const REFRESH_TOKEN_KEY = "refreshToken"

export const CLOUDINARY_URL = "https://res.cloudinary.com/kunstbende/"

export const NAV_HEIGHT = "80px"
