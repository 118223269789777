export const Button = {
  baseStyle: {
    borderRadius: 6,
    textDecoration: "none !important",
    color: "white",
  },
  variants: {
    pink: {
      bgColor: "pink.500",
    },
  },
}
