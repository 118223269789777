import * as React from "react"
import ReactGA from "react-ga"
import TagManager from "react-gtm-module"

import { useRouteChanged } from "lib/hooks/useRouteChanged"

import { IS_PRODUCTION } from "../lib/config"

const TRACKING_CODE = "UA-1721139-16"
const tagManagerArgs = {
  gtmId: "GTM-5JCM72R",
}

export function Analytics() {
  React.useEffect(() => {
    if (IS_PRODUCTION) {
      // Create the GTM script element
      const gtmScript = document.createElement("script")
      gtmScript.type = "text/javascript"
      gtmScript.async = true
      gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${tagManagerArgs.gtmId}`

      // Add the data-cookieconsent attribute
      gtmScript.setAttribute("data-cookieconsent", "ignore")

      // Append the GTM script to the document head
      document.head.appendChild(gtmScript)

      // Initialize GTM using react-gtm-module
      ReactGA.initialize(TRACKING_CODE)
      TagManager.initialize(tagManagerArgs)

      // Cleanup function to remove the GTM script when unmounting
      return () => {
        document.head.removeChild(gtmScript)
      }
    }
  }, [])

  useRouteChanged((url) => {
    if (IS_PRODUCTION) {
      ReactGA.set({ page: url })
      ReactGA.pageview(url)
    }
  }, [])

  return null
}
