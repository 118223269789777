export const colors = {
  pink: {
    50: "#ffb5d1",
    100: "#ffb5d1",
    200: "#f987b0",
    300: "#f55791",
    400: "#f02872",
    500: "#EA1060",
    600: "#790331",
    700: "#4b001d",
    800: "#1f000b",
    900: "#1f000b",
  },
  orange: {
    50: "#ffeedb",
    100: "#ffd2ae",
    200: "#ffb57e",
    300: "#ff994c",
    400: "#ff7b1a",
    500: "#ED6500",
    600: "#b44c00",
    700: "#813500",
    800: "#4f2000",
    900: "#200800",
  },
  teal: {
    50: "#E6FFFA",
    100: "#E6FFFA",
    200: "#B2F5EA",
    300: "#81E6D9",
    400: "#4FD1C5",
    500: "#38B2AC",
    600: "#319795",
    700: "#2C7A7B",
    800: "#285E61",
    900: "#234E52",
  },
  blue: {
    50: "#e4efff",
    100: "#bccff7",
    200: "#92afed",
    300: "#688fe4",
    400: "#1A4091",
    500: "#1A4091",
    600: "#1A4091",
    700: "#1A4091",
    800: "#11306d",
    900: "#071d44",
  },
  yellow: {
    50: "#FEF6B8",
    100: "#FEF08B",
    200: "#FDDF02",
    300: "#FDDF02",
    400: "#FDDF02",
    500: "#FDDF02",
    600: "#988601",
    700: "#655901",
    800: "#332D00",
    900: "#332D00",
  },
  gray: {
    50: "#FBFAFA",
    100: "#F3F3F3",
    200: "#E9E8E8",
    300: "#D6D5D5",
    400: "#AFAEAE",
    500: "#818080",
    600: "#565555",
    700: "#202020",
    800: "#121212",
    900: "#121212",
  },
}
